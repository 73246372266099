<template>
  <div>
    <div class="floating">
      <div class="phone_box">
        <img src="../assets/index/phone.png" alt="" srcset="" />
        <div class="copywriting">电话咨询</div>
        <div class="phone">
          <div>
            <img src="../assets/index/message_icon.png" alt="" srcset="" />
            <span>400-807-0177</span>
          </div>
          <div>了解详情请拨打以上电话</div>
        </div>
      </div>
      <div class="drived"></div>
      <div class="message" @click="show_message_box">
        <img src="../assets/index/w_liuyan.png" alt="" />
        <div class="copywriting">留言咨询</div>
      </div>
    </div>

    <div class="form_box" v-if="flag_form">
      <div class="model" @click="hidden_message_box"></div>
      <div class="form_box_content">
        <div v-if="is_submit_success" class="form_success">
          <img src="../assets/index/form/chenggong.png" alt="" />
          <div class="tips">感谢您的提交，我们将尽快和您联系。</div>
        </div>
        <div v-else>
          <el-form :model="form" :rules="rules" ref="form" class="form">
            <el-form-item prop="customer_name">
              <el-input placeholder="姓名(必填)" v-model="form.customer_name">
                <img slot="prefix" src="../assets/index/form/xingming.png" />
              </el-input>
            </el-form-item>
            <el-form-item prop="customer_phone">
              <el-input
                placeholder="联系电话(必填)"
                v-model="form.customer_phone"
              >
                <img slot="prefix" src="../assets/index/form/shouji (1).png" />
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="公司名称(选填)"
                v-model="form.customer_company"
              >
                <img slot="prefix" src="../assets/index/form/gongsi.png" />
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="职位(选填)"
                v-model="form.customer_position"
              >
                <img slot="prefix" src="../assets/index/form/gongzuo.png" />
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="微信号(选填)"
                v-model="form.customer_wechat_number"
              >
                <img slot="prefix" src="../assets/index/form/weixin (1).png" />
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="邮箱(选填)" v-model="form.customer_email">
                <img
                  slot="prefix"
                  src="../assets/index/form/youxiang (2).png"
                />
              </el-input>
            </el-form-item>
            <el-form-item prop="remark">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请于此详细说明需求（必填）"
                v-model="form.remark"
              >
              </el-input>
            </el-form-item>
            <el-button class="submit" :loading="is_loading" @click="submit" type="success">提交</el-button>
            <!-- <div class="submit" :disabled="is_loading" @click="submit">提交</div> -->
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addCustomerMessage } from "@/api/index";
import { Message } from "element-ui";
export default {
  name: "PCConncatUs",
  components: {},
  props: {},
  setup() {},
  data() {
    return {
      form: {
        customer_name: "",
        customer_phone: "",
        customer_company: "",
        customer_position: "",
        customer_wechat_number: "",
        customer_email: "",
        remark: "",
      },
      flag_form: false,
      rules: {
        customer_name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        customer_phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入详细说明需求", trigger: "blur" },
        ],
      },
      is_submit_success: false,
      is_loading:false
    };
  },
  methods: {
    show_message_box() {
      this.flag_form = true;
    },
    hidden_message_box() {
      this.flag_form = false;
      this.form = {
        customer_name: "",
        customer_phone: "",
        customer_company: "",
        customer_position: "",
        customer_wechat_number: "",
        customer_email: "",
        remark: "",
      };
      this.is_submit_success = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let paramas = this.form;
          Object.keys(paramas).map((key) =>
            !paramas[key] ? delete paramas[key] : ""
          );
          this.is_loading = true
          addCustomerMessage(paramas).then((res) => {
            if (res.data.code == 200) {
              this.is_submit_success = true;
               this.is_loading = false
            } else {
              Message.error(res.data.message[0]);
               this.is_loading = false
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
.floating {
  width: 90px;
  height: 180px;
  background: #45B035;
  box-shadow: 0px 4px 8px 0px rgba(114, 152, 24, 0.27);
  border-radius: 6px;
  position: fixed;
  top: 388px;
  right: 68px;
  z-index: 999;
  .phone_box,
  .message {
    width: 100%;
    height: 89px;
    cursor: pointer;
    & > img {
      width: 30px;
      height: 30px;
      margin: 13px 30px 10px 30px;
    }
    .copywriting {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .phone_box {
    position: relative;
    &:hover {
      .phone {
        /* opacity: 1; */
        display: block;
      }
    }
    .phone {
      width: 263px;
      height: 83px;
      text-align: center;
      /* line-height: 89px; */
      /* opacity: 0; */
      display: none;
      position: absolute;
      left: -269px;
      top: 0;
      transition: all 1s;
      background-repeat: no-repeat;
      background-image: url("../assets/index/message_border.png");
      & div:nth-child(1) {
        font-size: 24px;
        font-weight: 500;
        color: #45B035;
        height: 42px;
        margin-top: 4px;
        img {
          width: 26px;
          height: 26px;
          margin-right: 5px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
        }
      }
      & div:nth-child(2) {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .drived {
    width: 74px;
    height: 1px;
    background: #fff;
    opacity: 0.7;
    margin: 0 auto;
  }
}
.form_box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .model {
    width: 100%;
    height: 100%;
    background: #101010;
    opacity: 0.4;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .form_box_content {
    width: 500px;
    height: 614px;
    background: #edeaea;
    border-radius: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -250px;
    margin-top: -307px;
    z-index: 1000;
    .form {
      width: 420px;
      margin: 38px 40px 0 40px;
      img {
        margin-top: 11px;
      }
      .submit {
        width: 100%;
        height: 40px;
        background: #45B035;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        /* line-height: 40px; */
        margin-top: 45px;
        cursor: pointer;
      }
    }
    .form_success {
      width: 420px;
      margin: 0 40px 0 40px;
      img {
        width: 64px;
        height: 64px;
        margin: 107px 217px 65px 170px;
      }
      .tips {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
